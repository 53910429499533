import React from 'react'
import Layout from 'src/layouts/BaseLayout'

import pageContext from './pageContext.json'

import HeroOrlandoCity from './sections/hero-orlando-city/_index'
import InterCoStadium from './sections/interco-stadium/_index'
import PrimeiroPatrocinador from './sections/primeiro-patrocinador/_index'
import CartaoDeCreditoDebito from './sections/cartao-de-credito-debito/_index'
import FooterUs from 'src/components/Us/FooterUS/_index'

const IntercoOrlandoCityPT = () => {
  const lang = 'pt'

  return (
    <Layout pageContext={pageContext}>
      <HeroOrlandoCity lang={lang} />
      <InterCoStadium lang={lang} />
      <PrimeiroPatrocinador lang={lang} />
      <CartaoDeCreditoDebito lang={lang} />
      <FooterUs
        section='dobra_05'
        slugPT={pageContext.slug}
        slugEN={pageContext.pageEN}
        bgColor='bg-grayscale--500'
        lang={lang}
      />
    </Layout>
  )
}

export default IntercoOrlandoCityPT
